import * as React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { Link } from "gatsby";
import Layout from "../../components/layout/layout";

import "./services.scss";

const ServicesPage = () => {
  return (
    <Layout>
      <>
        <section
          className="sec-common-page-heading"
          style={{ backgroundImage: "url('../images/laughing-doctors.jpg')" }}
        >
          <Container>
            <div className="d-flex justify-content-between align-items-center">
              <h2>Services</h2>
              <Breadcrumb>
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Services</li>
              </Breadcrumb>
            </div>
          </Container>
        </section>

        <section className="sec-service-page">
          <Container>
            <div className="service-content">
              <h3>Client services</h3>
              <p>
                Our aim is to build, maintain and leave a lasting positive
                impact among all our clients. We pride ourselves in providing
                superior services delivered by our dedicated caring team. We
                have a wealth of experience and expertise to meet all of our
                clients staffing and care needs.
              </p>

              {/* <p className="mb-5">
                We ensure all staff training and qualifications are up to date
                and more importantly up to the required standard, allowing our
                staff to have the skills and the up to date knowledge to work
                competently.
              </p> */}
              <h3>Recruitment Services</h3>
              <p>
                We take pride in our ethical approach to recruitment, and we
                ensure that all our candidates are fully compliant with the
                necessary UK healthcare regulations and standards. Our rigorous
                screening process ensures that we only provide our clients with
                the best candidates who meet their specific requirements.
              </p>
              <h3>Job Matching</h3>
              <p>
                One of the essential services FHS provides is job matching. We
                work with healthcare providers across the UK to identify job
                openings and match them with qualified candidates from around
                the world. We deeply understand the UK healthcare system and can
                help candidates find positions that match their skills,
                experience, and career goals.
              </p>
              <h3>Visa Support</h3>
              <p>
                Securing a work visa can be a complex and time-consuming
                process, especially for international healthcare professionals
                who are not familiar with the UK immigration system. FHS
                provides visa support, helping candidates navigate the
                application process and ensuring they meet all of the
                requirements for a work visa.
              </p>
              <h3>Cultural Orientation</h3>
              <p>
                Moving to a new country can be a daunting experience, especially
                for healthcare professionals who are used to a different culture
                and way of life. We provide cultural orientation, helping
                candidates understand the UK healthcare system, local customs,
                and cultural norms. This can help candidates adjust quickly to
                their new environment and feel more comfortable in their new
                job.
              </p>
              <h3>Accommodation Support</h3>
              <p>
                Finding suitable accommodation in the UK can be challenging,
                especially in major cities where housing is in high demand. FHS
                will advise on accommodation support, helping candidates find
                safe and affordable housing in a convenient location close to
                their workplace.
              </p>
              <h3>Staffing Services </h3>
              <p>
                Our medical staffing services offer several advantages to the
                UK's NHS, care homes, and private healthcare institutions. One
                of the most significant benefits of using our staffing services
                is that we can fill vacant positions quickly and efficiently. We
                have access to a vast pool of qualified and experienced
                healthcare professionals who are readily available to take up
                vacant positions at short notice. This enables healthcare
                establishments to maintain their staffing levels and ensure
                continuity of care for their patients.
              </p>
              <p className="mb-5">
                Another advantage of using our medical staffing services is that
                they help to reduce the administrative burden on healthcare
                establishments. We handle the recruitment and onboarding
                processes, including screening, interviewing, vetting
                candidates, checking references, and verifying qualifications.
                This frees up valuable time and resources for healthcare
                establishments to focus on delivering high-quality patient care.
              </p>
              <p className="mb-5">
                Our staffing services also offer flexibility to healthcare
                establishments, particularly during times of high demand. We can
                provide temporary or permanent staff, part-time or full-time
                staff, and cover shifts on short notice. This helps to ensure
                that healthcare establishments can meet the needs of their
                patients and maintain high standards of care.
              </p>
              <p className="mb-5">
                Instead of investing in costly recruitment campaigns and
                maintaining an internal HR department, healthcare establishments
                can partner with us and pay for the services they require on an
                as-needed basis. This enables them to manage their budgets more
                effectively while maintaining high care standards.
              </p>
            </div>
          </Container>

          <Container>
            <Row>
              <Col lg="6">
                <div className="service-content">
                  <h5 className="mb-3">Who we supply:</h5>
                  <ul className="mb-5">
                    <li>
                      <i className="bi bi-check-lg"></i>GRI
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>NHS Hospitals and
                      Private Hospitals
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Bupa Care Homes
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Four seasons Healthcare
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Restful Homes Group
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Alpha care homes
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Spring care homes
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Caram (AH) Limited
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Choices Housing
                    </li>
                  </ul>

                  <h5 className="mb-3">
                    All of our staff follow a robust recruitment procedure, see
                    below:
                  </h5>
                  <ul className="mb-5">
                    <li>
                      <i className="bi bi-check-lg"></i>Enhanced DBS checks
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>They must speak,
                      understand, read and write the English language fluently{" "}
                      <br />
                      (evidenced by completing the interview questionnaire){" "}
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Competency and skills
                      check
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Completing full
                      application forms
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Two references verified
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Employment history for
                      last 10 years
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Previous experience in
                      the care sector
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Right to work in the UK
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Proof of ID/ photograph
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Proof of professional
                      indemnity insurance (for Nurses)
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Confirmation of NMC PIN
                      Number
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Face to face interview
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Immunisations
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>ID badge uniform
                      provided
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg="6">
                <div className="service-content">
                  <h5 className="mb-3">Training provided to our staff</h5>
                  <ul className="mb-5">
                    <li>
                      <i className="bi bi-check-lg"></i>One full day induction
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>New care certificate
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Health and safety
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Moving and transferring
                      adults safely
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Safe handling of
                      medication
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Infection control
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Safeguarding of
                      vulnerable adults
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Person centred care
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Dementia awareness
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>MAPA/DMI
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Mental capacity and
                      deprivation of liberty safeguards
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Record keeping
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Care of the dying
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Eating disorder training
                    </li>
                  </ul>

                  <h5 className="mb-3">Specialist courses for nurses</h5>
                  <ul className="mb-5">
                    <li>
                      <i className="bi bi-check-lg"></i>Diabetes and insulin
                      management
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Syringe driver
                      management
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Caring for patients with
                      multiple sclerosis/ Tracheostomy Care
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Neurological
                      observations
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Catheterisation (Male
                      and Female)
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Principles of Nursing
                      Practice
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Clinical Governance
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>End of Life Care
                    </li>
                    <li>
                      <i className="bi bi-check-lg"></i>Supporting People’s
                      Nutritional Needs
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>

            {/* <div className="service-content">
                            <h3>Client services</h3>
                            <p>Our aim is to build, maintain and leave a lasting positive impact among all our clients. We pride ourselves in providing superior services delivered by our dedicated caring team. We have a wealth of experience and expertise to meet all of our clients staffing and care needs.</p>

                            <p className="mb-5">We ensure all staff training and qualifications are up to date and more importantly up to the required standard, allowing our staff to have the skills and the up to date knowledge to work competently.</p>

                                
                            <h5 className="mb-3">Who we supply:</h5>
                            <ul className="mb-5">
                                <li><i className="bi bi-check-lg"></i>GRI</li>
                                <li><i className="bi bi-check-lg"></i>NHS Hospitals and Private Hospitals</li>
                                <li><i className="bi bi-check-lg"></i>Bupa Care Homes</li>
                                <li><i className="bi bi-check-lg"></i>Four seasons Healthcare</li>
                                <li><i className="bi bi-check-lg"></i>Restful Homes Group</li>
                                <li><i className="bi bi-check-lg"></i>Alpha care homes</li>
                                <li><i className="bi bi-check-lg"></i>Spring care homes</li>
                                <li><i className="bi bi-check-lg"></i>Caram (AH) Limited</li>
                                <li><i className="bi bi-check-lg"></i>Choices Housing</li>
                            </ul>

                            <h5 className="mb-3">All of our staff follow a robust recruitment procedure, see below:</h5>
                            <ul className="mb-5">
                                <li><i className="bi bi-check-lg"></i>Enhanced DBS checks</li>
                                <li><i className="bi bi-check-lg"></i>They must speak, understand, read and write the English language fluently <br/>(evidenced by completing the interview questionnaire)   </li>
                                <li><i className="bi bi-check-lg"></i>Competency and skills check</li>
                                <li><i className="bi bi-check-lg"></i>Completing full application forms</li>
                                <li><i className="bi bi-check-lg"></i>Two references verified</li>
                                <li><i className="bi bi-check-lg"></i>Employment history for last 10 years</li>
                                <li><i className="bi bi-check-lg"></i>Previous experience in the care sector</li>
                                <li><i className="bi bi-check-lg"></i>Right to work in the UK</li>
                                <li><i className="bi bi-check-lg"></i>Proof of ID/ photograph</li>
                                <li><i className="bi bi-check-lg"></i>Proof of professional indemnity insurance (for Nurses)</li>
                                <li><i className="bi bi-check-lg"></i>Confirmation of NMC PIN Number</li>
                                <li><i className="bi bi-check-lg"></i>Face to face interview</li>
                                <li><i className="bi bi-check-lg"></i>Immunisations</li>
                                <li><i className="bi bi-check-lg"></i>ID badge uniform provided</li>
                            </ul>
                        
                            <h5 className="mb-3">Training provided to our staff</h5>
                            <ul className="mb-5">
                                <li><i className="bi bi-check-lg"></i>One full day induction</li>
                                <li><i className="bi bi-check-lg"></i>New care certificate</li>
                                <li><i className="bi bi-check-lg"></i>Health and safety</li>
                                <li><i className="bi bi-check-lg"></i>Moving and transferring adults safely</li>
                                <li><i className="bi bi-check-lg"></i>Safe handling of medication</li>
                                <li><i className="bi bi-check-lg"></i>Infection control</li>
                                <li><i className="bi bi-check-lg"></i>Safeguarding of vulnerable adults</li>
                                <li><i className="bi bi-check-lg"></i>Person centred care</li>
                                <li><i className="bi bi-check-lg"></i>Dementia awareness</li>
                                <li><i className="bi bi-check-lg"></i>MAPA/DMI</li>
                                <li><i className="bi bi-check-lg"></i>Mental capacity and deprivation of liberty safeguards</li>
                                <li><i className="bi bi-check-lg"></i>Record keeping</li>
                                <li><i className="bi bi-check-lg"></i>Care of the dying</li>
                                <li><i className="bi bi-check-lg"></i>Eating disorder training</li>
                            </ul>

                            <h5 className="mb-3">Specialist courses for nurses</h5>
                            <ul className="mb-5">
                                <li><i className="bi bi-check-lg"></i>Diabetes and insulin management</li>
                                <li><i className="bi bi-check-lg"></i>Syringe driver management</li>
                                <li><i className="bi bi-check-lg"></i>Caring for patients with multiple sclerosis/ Tracheostomy Care</li>
                                <li><i className="bi bi-check-lg"></i>Neurological observations</li>
                                <li><i className="bi bi-check-lg"></i>Catheterisation (Male and Female)</li>
                                <li><i className="bi bi-check-lg"></i>Principles of Nursing Practice</li>
                                <li><i className="bi bi-check-lg"></i>Clinical Governance</li>
                                <li><i className="bi bi-check-lg"></i>End of Life Care</li>
                                <li><i className="bi bi-check-lg"></i>Supporting People’s Nutritional Needs</li>
                            </ul>

                        </div> */}
          </Container>
        </section>
      </>
    </Layout>
  );
};

export default ServicesPage;
